<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" :style="blockStyle" container-class="search-header">
        <cms-component-text :component="block.title" v-bind="componentProps"/>
        <cms-component-text :component="block.subtitle" v-bind="componentProps"/>
        <e-search-input :placeholder="tr(block.placeholder)" @clear="clearSearch" class="mt mb-2" :autofocus="block.mediaProxy.autofocus" :class="block.mediaProxy.fillWidth ? 'fill-width': 'auto-width'" :size="block.mediaProxy.size" v-model="search" :readonly="isEditMode"/>

            <div class="search-results" :mode="block.container" :show-limit="isSelected && !isChild" :style="containerStyle" :align="block.mediaProxy.align">
                <cms-block-list-item v-for="item in searchResults" :key="`list-item-${item.id}`" class="cms-list-item" :block-component="bodyComponent" no-body :item="item"
                                     @click.native="selectItem(item, child)" :block="child" :interactive="!isEditMode" is-child :selected="blockSelection === child" :editable="editable">
                    <span v-if="block.displayKeywords && search">{{getMatchingKeywords(item)}}</span>
                </cms-block-list-item>
            </div>

    <!--        <item-summary v-if="itemSelected" :item="itemSelected" @hidden="unselectItem"/>-->

            <template v-if="isKioskDevice">
                <virtual-keyboard v-show="!itemSelected" ref="keyboard" theme="transparent" v-model="search" @change="onKeyboardChange" @delete="clearSearch" @enter="onEnter"/>
            </template>
    </cms-block>
</template>

<script>
import ItemSummary from "@/components/cms/item-summary.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";
import VirtualKeyboard from "@/components/virtual-keyboard.vue";
import {swal} from "vue-swal";
import ESearchInput from "../../../../../vue-components/components/e-search-input.vue";

// cms-block-search #hot-reload-debug
export default {
    name: `cms-block-search`,
    extends: CmsBlock,
    components: {
        CmsComponentText,
        VirtualKeyboard,
        ItemSummary,
        ESearchInput,
        CmsBlockListItem: () => import(`@/components/editor/rendering/blocks/cms-block-list-item.vue`),
        CmsBlock
    },
    data() {
        return {
            search: ``,
            itemSelected: null
        }
    },
    computed: {
        child() {
            return this.block.children[0];
        },
        bodyComponent() {
            if (this.block.mediaProxy.layout === `cards`) {
                return `b-card`;
            } else {
                return `div`;
            }
        },
        blockStyle() {
            let style = {
                "--list-item-spacing": this.block.mediaProxy.spacing,
                "--list-item-columns": this.block.mediaProxy.columns
            }
            if (this.block.divider.active) {
                style[`--divider-width`] = this.block.divider.mediaProxy.width;
                style[`--divider-color`] = this.block.divider.mediaProxy.color;
            }
            if (this.block.mediaProxy.maxHeight !== `none`) {
                style[`--search-max-height`] = this.block.mediaProxy.maxHeight;
            }
            style[`--border-radius`] = this.block.mediaProxy.borderRadius;
            return style;
        },
        containerStyle() {
            let style = {};

            if (this.block.background === `color` && this.block.backgroundColor) {
                // style[`--scheme-bg`] = this.cmsWebsite.getColor(`background`);
                style.background = this.block.mediaProxy.colorScheme.background;
            }

            if (this.block.borders?.active) {
                style.borderWidth = this.block.borders.mediaProxy.width;
                style.borderColor = this.block.borders.mediaProxy.color;
                style.borderStyle = this.block.borders.mediaProxy.style;
            }

            return style;
        },
        searchRegexp() {
            const normalizedSearch = this.search.normalize(`NFKD`).replace(/\W/g, ``);
            return new RegExp(normalizedSearch, `gi`);
        },
        searchResults() {
            let list = [];
            if (this.block.itemType === `inherit` || this.block.itemType === `universal-search`) {
                list = this.cms.getItemsByType(this.cmsPage.itemType);
            } else if (this.block.itemType === `inherit`) {
                list = this.cms.getItemsByType(this.cmsPage.itemType);
            } else {
                list = this.block.itemList;
            }
            if (!this.search || this.search.length < 2) {
                if (this.isEditMode || !this.block.displayOnSearchOnly) {
                    if (list.length > 5) {
                        return list.slice(0, 5);
                    } else {
                        return list;
                    }
                } else {
                    return null;
                }
            } else {
                list = list.filter(l => l.matchSearch(this.searchRegexp));
                if (list.length > this.block.mediaProxy.maxSearchResult) {
                    return list.slice(0, this.block.mediaProxy.maxSearchResult);
                } else {
                    return list;
                }
            }
        }
    },
    methods: {
        onEnter() {
            console.log(`submitted`);
            if (!this.search) {
                swal({
                    title: this.tr(`What are you looking for?|Que cherchez-vous ?`),
                    text: this.tr(`Please type the first letters of the department you are looking for|Merci d'écrire les premières lettres de ce que vous cherchez`),
                    icon: `info`
                });
            } else if (this.searchResults.length > 0) {
                swal({
                    title: this.tr(`Found ${this.searchResults.length}|Trouvé ${this.searchResults.length} résultats`),
                    text: this.tr(`Tap on the result to view it's location|Appuyez sur un des résultats pour afficher son emplacement`),
                    icon: `info`
                });
            } else if (this.searchResults.length === 0) {
                swal({
                    title: this.tr(`Couldn't find|Nous n'avons rien trouvé`),
                    text: this.tr(`Try with the name of the department instead|Essayez avec le nom du département à la place`),
                    icon: `info`
                });
            }
        },
        onKeyboardChange(input) {
            this.search = input;
        },
        clearSearch() {
            if (this.$refs.keyboard) {
                this.$refs.keyboard.clear();
            }
            this.search = ``;
            if (this.$refs.search) {
                this.$refs.search.focus();
            }
        },
        selectItem() {
            if (this.isEditMode) {
                this.$emit(`block-selected`, this.child);
            }
        },
        getMatchingKeywords(item) {
            if (!this.search) {
                return ``;
            } else {
                return item.getMatchingKeywords(this.searchRegexp);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-search {
    --divider-width: 0;
    --divider-color: 0;
    --list-item-columns: 0;
    --list-item-spacing: 10px;
    --search-max-height: none;

    ::v-deep .search-header {
        column-count: initial !important;
        widows: initial !important;
        orphans: initial !important;
    }

    ::v-deep .e-search-input {
        &.fill-width {
            width: 100%;
        }
        &.auto-width {
            width: 300px;
            max-width: 100%;
        }

        &.size-xxl {
            .search-icon, .clear-icon {
                width: 60px;
            }
            .form-control {
                padding-inline: 60px;
            }
        }

        &.size-lg {
            &.auto-width {
                width: 400px;
            }
            .search-icon, .clear-icon {
                width: 44px;
            }
            .form-control {
                padding-inline: 44px;
            }
        }

        &.size-sm {
            &.auto-width {
                width: 200px;
            }
            .search-icon, .clear-icon {
                width: 28px;
            }
            .form-control {
                padding-left: 28px;
            }
        }

        .search-icon, .clear-icon {
            font-size: larger;
        }

        .clear-icon {
            border-radius: var(--border-radius);
            color: var(--danger)
        }

        .form-control {
            &::placeholder {
                overflow: visible;
            }
        }
    }

    ::v-deep .search-results {
        width: 100%;
        border-radius: 10px;

        &.align-left .e-search-input {
            margin-right: auto;
        }
        &.align-center .e-search-input {
            margin-left: auto;
            margin-right: auto;
        }
        &.align-right .e-search-input {
            margin-left: auto;
        }
    }

    ::v-deep .cms-block {
        margin-bottom: var(--list-item-spacing);
        break-inside:avoid;
    }

    .search-results {
        height: var(--search-max-height);
        max-height: var(--search-max-height);
        overflow: hidden auto;
    }

    .cms-list-item {
        width: 100%;
    }

    &.layout-default {
        ::v-deep .cms-block:not(:last-child) {
            border-bottom: var(--divider-width) solid var(--divider-color);
        }
    }

    &.layout-cards {
        ::v-deep > .search-results {
            display: block;
            column-count: var(--list-item-columns);
            column-gap: var(--list-item-spacing);
            orphans: 1;
            widows: 1;
            line-height: 1;
        }
    }
}
</style>
