<template>
    <b-modal :title="tr(item.typeDef.name)" size="lg" :dialog-class="modalClass" scrollable v-model="visible" @hidden="hide"
             :centered="centered" :hide-header="!isMobileLayoutOrPreview" :hide-backdrop="hideBackdrop">
        <b-overlay :show="loading">
            <template v-if="item">
                <b-img class="mb-3 cover-image" fluid :src="item.cover_url"/>

                <b-media>
                    <template #aside>
                        <i class="fa-3x pt-2" :class="[fontAwesomePrefix, item.glyph ? item.glyph : item.typeDef.glyph]"/>
                    </template>
                    <h2 class="title">{{item.name|tr}}</h2>
                    <div v-if="item.headline" class="subtitle">{{item.headline}}</div>
                    <div v-else class="subtitle">{{tr(item.typeDef.name)}}</div>

                    <p class="description" v-if="tr(item.description)" v-html="tr(item.description)"/>
                    <b-btn v-if="item.phoneNumber" size="lg" class="mb-3" variant="white">
                        <i class="fal fa-phone"/> {{item.phoneNumber}} <span v-if="item.extension">ext {{item.extension}}</span></b-btn>
                </b-media>
            </template>
        </b-overlay>

        <template #modal-footer>
            <div class="w-100 text-right">
                <b-btn v-if="!isMobileLayoutOrPreview" @click="hide" class="float-left">
                    <span v-tr>Close|Fermer</span>
                </b-btn>

                <template v-if="item">
                    <b-btn variant="primary" :class="isMobileLayoutOrPreview ? 'mb-3' : 'mr-3'" :block="isMobileLayoutOrPreview" v-if="mapLink" @click="hide" :to="mapLink">
                        <i class="fal fa-map-location mr-2"/>
                        <span v-tr>View on Map|Afficher sur le plan</span>
                    </b-btn>

                    <b-btn variant="dark" @click="hide" :block="isMobileLayoutOrPreview" v-if="itemDetailPage" :to="itemDetailPage">
                        <i class="fal fa-info-circle mr-2"/>
                        <span v-tr>Information|Informations</span>
                    </b-btn>
                </template>
                <b-btn variant="primary" v-else :disabled="true">
                    <span class="mr-1" v-tr>Loading|Chargement</span>
                    <i class="fal fa-spinner-third fa-spin"/>
                </b-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import CmsItem from "../../helpers/cms/CmsItem.js";

export default {
    name: `item-summary`,
    props: {
        item: {type: CmsItem, required: true},
        modalClass: {type: String},
        centered: {type: Boolean, default: true},
        hideBackdrop: {type: Boolean},
    },
    data() {
        return {
            loading: false,
            visible: true
        }
    },
    computed: {
        mapLink() {
            if (this.item.mapId) {
                return `/map?start=${this.item.mapId}`;
            } else if (this.item.department && this.item.department.mapId) {
                return `/map?start=${this.item.department.mapId}`;
            }
            return null;
        },
        itemDetailPage() {
            if (!this.item.typeDef.viewUrl) {
                return null;
            }
            return this.item.typeDef.viewUrl.replace(`{id}`, this.tr(this.item.permalink) || this.item.id);
        }
    },
    methods: {
        hide() {
            this.visible = false;
            this.$emit(`hidden`);
        }
    }
}
</script>

<style lang="scss" scoped>
.cover-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.logo {
    width: 250px;
    height: 150px;

    object-fit: contain;
}

.description {
    font-size: 15pt;
}
</style>
